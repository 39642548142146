.WelcomeScreen {
  position: relative;
  padding: 25% 14px 0 14px;
  background: var(--grey-dark-1);
  z-index: 1;
  height: fit-content;
  min-height: calc(100vh - var(--header-height) - 45px);
  width: 100%;
  overflow: hidden;
}

.WelcomeScreen h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 26px 0 !important;
  color: #cecece;
}

.CreateRoomSection {
  width: 90%;
}

.CreateRoomButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}

.CreateButton {
  width: 46%;
  height: var(--button-height);
  font-size: 16px !important;
}

.FormDivider {
  width: 100%;
  display: flex;
  margin: 1.5em 0;
}

.FormDivider>div {
  padding: 0 1em;
}

.FormDivider::before,
.FormDivider::after {
  width: 100%;
  content: '';
  transform: translateY(50%);
  border-top: 1px solid #4A536B;
}

.JoinButton {
  height: var(--button-height);
  width: 100%;
  font-size: 16px !important;
}

.BackdropIcon {
  position: absolute;
  z-index: -1;
}

.BackdropIcon:nth-child(1) {
  right: -12%;
  top: -5%;
  transform: rotate(-160deg);
}

.BackdropIcon:nth-child(2) {
  left: -15%;
  top: 15%;
  transform: rotate(70deg);
}

.BackdropIcon:nth-child(3) {
  right: -15%;
  top: 50%;
  transform: rotate(110deg);
}

.LogoRight {
  display: none;
  max-width: 760px;
}

.TagContainer {
  margin-top: 8px !important;
}

.TagContainer>div {
  margin: 4px;
}

.LeftSide {
  max-width: 445px;
  min-height: 500px;
}

.RequiredAsterix{
  color: var(--red-text);
}

.AgreementText a,
.AgreementText a:visited {
  color: var(--red-text);
  text-decoration: none !important;
  font-weight: 500;
}

@media screen and (max-height: 700px) {
  .WelcomeScreen {
    padding-top: 5%;
  }
}


@media screen and (min-width: 750px) {
  .WelcomeScreen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 64px;
    height: calc(100vh - var(--header-height));

  }

  .LogoRight {
    display: block;
    width: 50vw;
    height: fit-content;
    margin-left: -124px;
  }

  .LogoRight svg {
    height: 70vh;
  }
  
  .BackdropIcon {
    display: none;
  }

}

@media screen and (min-width: 1200px) {
  .LeftSide {
    width: 35%;
    margin-left: var(--side-margin);
    height: fit-content;
  }

  .LogoRight {
    display: block;
    width: 53vw;
    margin-left: 1vw;
    height: fit-content;
  }

  .LogoRight svg {
    width: 100%;
    height: 100%;
  }

}