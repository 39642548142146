.header {
  display: flex;
  height: 70px;
  align-items: center;
  background: var(--grey-header);;
  z-index: 100;
  justify-content: space-between;
}

.header .Left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header h1 {
  font-size: 1.5em;
  font-weight: 500;
}

.header svg:not(span > svg) {
  margin: 0 8px;
  width: 42px;
  height: 42px;
}

.LogoLink {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

a.LogoLink {
  text-decoration: none !important;
  color: white !important;
}

.ActionButton {
  margin-right: 16px
}

.ControlItems {

}

.ControlItems > * {

}

.ActionIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ActionIcons svg {
  cursor: pointer;
}

.ActionIcons svg:not(span > svg) {
  margin: 0 8px 0 6px;
  width: 28px;
  height: 28px;
  fill: var(--text-grey-2);
}

@media screen and (orientation: landscape) {
  .ActionIcons {
    width: 45vw;
  }
}

@media screen and (orientation: portrait) and (min-width: 760px) {
  .ActionIcons {
    width: 50vw;
  }
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  .ActionIcons {
    width: 35vw;
  }
}