.AccessControlListManager {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.AccessControlListManager p {
    margin-top: 0;
    margin-bottom: 34px;
    color: var(--text-grey-2);
}

.PermissionMatrix {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    column-gap: 10px;
    row-gap: 15px;
    align-items: center;
    justify-items: center;
}

.Header {
    font-weight: 700;
}

.PermissionName {
    justify-self: start;
}

.Submit {
    align-self: flex-end;
    margin-top: 24px !important;
}