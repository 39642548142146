.NotFound {
    padding: 0 14px 64px 14px;
}

.NotFound h1 {
    margin-top: 64px;
}

@media screen and (min-width: 750px) {
    .NotFound {
      padding: 0 64px 64px 64px;
    }
}

@media screen and (min-width: 1200px) {
    .NotFound > * {
      margin-left: var(--side-margin) !important;
    }
    .NotFound p {
      font-size: 18px;
      width: 50%;
    }
  }