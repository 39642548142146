.YouTubeShortsPlayerOverlay {
    position: absolute;
    top: 10%;
    right: 0;
    height: 80% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
}

.NavButton {
    cursor: pointer;
    background: var(--grey-input);
    opacity: 0.9;
    padding: 6px;
    border-radius: 50%;
    margin-bottom: 12px;
    margin-right: 12px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
}

.NavButton:hover {
    opacity: 1;
}

.NavButton>svg {
    width: 24px;
    height: 24px;
}

.MuteButton > svg {
    cursor: pointer;
    opacity: 0.8;
    height: 28px;
    width: auto;
    pointer-events: all;
    user-select: none;
}