.SuggestedVideos {
    display: flex;
    flex-flow: row wrap;
    margin-top: 1rem;
    cursor: pointer;
    width: 90%;
    justify-content: flex-start;
}

.Video {
    width: 24%;
    display: flex;
    flex-direction: column;
    margin-right: calc(1.3333333%);
    text-align: left;
}

.Video:nth-child(4n) {
    margin-right: 0;
}

.Video  img {
    border-radius: var(--thumbnail-border-radius);
}

.Video .Title {
    font-size: 14px;
    margin-top: 6px;
    color: var(--text-grey-1);
}
.Video .Channel {
    font-size: 11px;
    margin-top: 4px;
    color: var(--text-grey-2);
}

@media screen and (min-width: 1400px) {

}
