.SearchResultsView {
  overflow-y: scroll;
  background: var(--grey-header);
  padding: 0 12px;
}

.SearchResult {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin: 16px 0;
}

.Thumbnail {
  width: 28%;
  height: 58px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.Thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Actions {
  align-self: flex-start;
  display: flex;
  margin-top: 3px;
  margin-left: 16px;
}

.Actions button:first-child {
  margin-right: 8px;
}

.ActionIcon {
  width: 32px !important;
}

.Description {
  margin-left: 12px;
  margin-top: 3px;
  align-self: flex-start;
  width: 55%;
  line-height: 18px;
}

.Title {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  max-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Channel {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  max-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-grey-2);
}

@media screen and (orientation: portrait) and (min-width: 760px) {
  .SearchResult {
    margin: 24px 0;
  }
  .Thumbnail {
    height: 116px;
  }
  .Description {

    margin-left: 18px;
    line-height: 22px;
  }
  .Title {
    font-weight: 500;
    font-size: 16px;
    max-height: 70px;
  }
  .Channel {
    font-size: 14px;
    max-height: 36px;
  }

}