.Page {
    padding: 32px 14px;
}

.Page>* {
    max-width: 900px;
}

.Page h1 {
    color: white;
}

.Page h2 {
    margin-top: 64px;
    color: white;
}

.Page p {
    font-size: 18px;
    color: var(--text-grey-1);
    line-height: 1.6em;
}

@media screen and (min-width: 750px) {
    .Page {
        padding-left: 64px;
        padding-right: 64px;
    }
}

@media screen and (min-width: 1200px) {
    .Page>* {
        margin-left: var(--side-margin) !important;
    }

    .Page p {
        font-size: 20px;
    }
}

@media screen and (min-width: 1500px) {
    .Page p {
        font-size: 22px;
    }
}
