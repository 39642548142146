.Tabs {
    height: 100%;;
}

.JoyTabs {
    height: 100%;
}

.TabPanel {
    height: 100%;
    background-color: var(--grey-dark-1);
    padding: 8px;
    overflow-y: scroll;
}

.Tab {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.Tab>svg {
    margin-right: 6px;
}

@media screen and (orientation: landscape) {
    .Tabs {
        width: stretch;
    }
}
