.Home {
  background-color: var(--grey-dark-1);
}


.Home h1 {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  margin: 0;
  margin-bottom: 24px;
}

.Home h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 12px 0;
  margin-bottom: 36px;
  width: 85%;
  color: #b1b1b1;
}
