.Chat {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  --icon-height: 30px;
}

.Messages {
  overflow-y: scroll;
  overflow-x: hidden;
  height: stretch;
  overflow-y: scroll;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding: 6px 12px;
}

.Message {
  margin-bottom: 4px;
}

.Message span.Username {
  color: grey;
  margin-right: 4px;
  font-weight: 500;
}

.Message a {
  overflow-wrap: anywhere;
}

.BottomBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.Right {
  display: flex;
  align-items: center;
}

.Icon {
  width: var(--icon-height) !important;
  height: var(--icon-height) !important;
  color: var(--text-grey-2) !important;
  margin-right: 6px !important;
  cursor: pointer;
}

.EmojiPickerPosition {
  position: relative;
}

.EmojiPickerAbsoluteWrapper {
  position: absolute !important;
  right: 8px;
  bottom: 8px;
}

.NoAccessText {
  color: var(--text-grey-2);
}