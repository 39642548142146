:root {
  --primary-color: #840405;

  --yellow: #C69108;
  --blue: #0B12B7;

  --red-text: #c60606;

  --grey-header: #181a20;
  --grey-dark-0: #111113;
  --grey-dark-1: #0B0E11;
  --grey-dark-2: #0E1115;
  --grey-dark-3: #171C23;
  --grey-dark-5: #19191F;
  --grey-dark-6: #1A1A22;

  --grey-input: #26272B;
  --grey-input-dark: #131318;


  --text-grey-1: #D0D0D0;
  --text-grey-2: #A8A8A8;

  --text-white: #FFFFFF;

  --thumbnail-border-radius: 24px;
  --input-border-radius: 10px;

  --header-height: 70px;
  --search-bar-height: 50px;
  --tabs-height: 52px;

  --button-height: 48px;

  --side-margin: 10%;

}

:fullscreen {
  z-index: -10;
}

.EmojiPickerReact.epr-dark-theme {
  background: var(--grey-header);
}

.epr-emoji-category-label {
  background-color: var(--grey-header) !important;
  backdrop-filter: blur(10px);
}


@media screen and (min-width: 2000px) {
  :root {
    --side-margin: 18%;
  }
}