.Playlist {
  width: 95%;
  margin-left: 2.5%;
}

.Playlist h3 {
  font-size: 16px;
  margin-bottom: 18px;
}

.InputWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.InputWrapper button {
  margin-left: 8px;
}

.Entries {
  margin-top: 24px
}

.Entry {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  margin: 16px 0;
}

.DragIcon {
  margin-right: 6px !important;
  width: 25px !important;
  height: auto !important;
}

.Thumbnail {
  width: 25%;
  height: 58px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.Thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Description {
  margin-left: 12px;
  margin-top: 3px;
  align-self: flex-start;
  width: 45%;
  line-height: 18px;
}

.Title {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  max-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Channel {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: var(--text-grey-2);
}

.Actions {
  align-self: flex-start;
  display: flex;
  margin-top: 3px;
  margin-left: 8px;
}

.Actions button:first-child {
  margin-right: 8px;
}

.ActionIcon {
  width: 32px !important;
}

.MoveIcons {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 6px;
  cursor: pointer;
}
