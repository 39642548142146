.VideoPlayer {
  background: #0B0E11;
  position: relative;
  width: 100%;
  height: calc(50vh - var(--header-height) - var(--search-bar-height));
}

.VideoPlayer.YouTubeShortsViewMode,
.VideoPlayer.YouTubeShortsViewMode .YouTubePlayer {
  height: calc(70vh - var(--header-height) - var(--search-bar-height));
}

.YouTubePlayer {
  width: 100%;
  height: calc(50vh - var(--header-height) - var(--search-bar-height));
}

.NoVideo {
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  position: relative;
}

.NoVideoBackground {
  display: none;
  position: absolute;
  top: 43%;
  left: 47%;
  transform: translate(-50%, -50%) scale(1.1);
}

.NoVideo h1,
.NoVideo h2 {
  z-index: 1;
  font-size: 20px;
}

.NoVideo h2 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}

.NoVideo h2 span {
  color: #FF0000;
}

.VideoControlsDisabler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: all;
}

@media screen and (orientation: landscape) {
  .VideoPlayer {
    height: 100%;
  }

  .YouTubePlayer {
    width: 100%;
    height: calc(100vh - var(--header-height)) !important;
  }

  .VideoPlayer.YouTubeShortsViewMode > div,
  .VideoPlayer>div {
    height: calc(100vh - var(--header-height));
    overflow: hidden;
  }
}


@media screen and (orientation: portrait) and (min-width: 760px) {
  .VideoPlayer {
    height: calc(50vh - var(--header-height) / 2) !important;
  }

  .VideoPlayer.YouTubeShortsViewMode > div,
  .VideoPlayer>div {
    height: calc(50vh - var(--header-height) / 2);
    overflow: hidden;
  }
  
  .YouTubePlayer {
    width: 100%;
    height: calc(50vh - var(--header-height) / 2) !important;
  }
}


@media screen and (orientation: landscape) and (min-width: 800px) and (min-height: 650px) {
  .VideoPlayer {
    height: calc(100% - 65px) !important;
  }

  .VideoPlayer.YouTubeShortsViewMode > div,
  .VideoPlayer>div {
    height: calc(100%);
    overflow: hidden;
  }

  .YouTubePlayer {
    height: calc(100%) !important;
  }
}

@media screen and (orientation: landscape) and (min-width: 800px) and (min-height: 1100px) {

}