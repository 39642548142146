.HowToScreen {
    padding: 64px 14px;
}

.Header {
    display: flex;
    flex-flow: row wrap;
}

.Header>* {
    width: 100%;
}

.Header>svg {
    max-height: 350px;
}

.RedText {
    color: var(--red-text);
}

.Step {
    background: var(--grey-input-dark);
    padding: 18px;
    border-radius: var(--thumbnail-border-radius);
    margin: 18px 0;
}

.Step h3 {
    margin-top: 0;
}

.MonsterImage {
    max-width: 100%;
}

@media screen and (min-width: 750px) {
    .HowToScreen {
        padding-left: 64px;
        padding-right: 64px;
    }

    .HowToScreen h1 {
        width: 70%;
    }

    .HowToScreen h2 {
        width: 60%;
    }
}

@media screen and (min-width: 1000px) {

    .Header {
        position: relative;
    }

    .Header>* {
        width: 50%;
    }

    .HeaderText {
        z-index: 1;
    }

    .Header>svg {
        align-self: flex-end;
        position: absolute;
        right: -2%;
        bottom: 0;
        z-index: 0;
        width: 56vw;
        max-width: none;
        max-height: none;
    }

    .Steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .Step {
        width: 32%;
        height: stretch;
    }

    .HowToScreen h1 {
        width: 100%;
    }

    .HowToScreen h2 {
        width: 70%;
    }

    .HeaderText {
        padding-bottom: 25vh;
    }
}

@media screen and (min-width: 1200px) {
    .HowToScreen>* {
        margin-left: var(--side-margin) !important;
    }

    .Header>svg {
        right: -2%;
        bottom: 0;
        width: 45vw;
    }
}

@media screen and (min-width: 1500px) {
    .HowToScreen h1 {
        width: 70%;
    }

    .HowToScreen h2 {
        width: 60%;
    }
}

@media screen and (min-width: 1800px) {
    .Header>svg {
        bottom: 0;
        width: 38vw;
    }
}


@media screen and (min-width: 2000px) {
    .HowToScreen {
        width: 90%;
    }

    .Header>* {
        width: 50%;
    }

    .HowToScreen h1 {
        width: 60%;
    }

    .HowToScreen h2 {
        width: 40%;
    }
}