.Shim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var(--grey-dark-3);
  opacity: 0.8;
  backdrop-filter: blur(20px);
}

.AcceptCookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--grey-dark-1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 10000;
}

.Text {
  width: 100%;
  max-width: 600px;
  font-size: 18px;
  text-align: center;
}

.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.Button:first-child {
  margin-right: 1em;
}

.AcceptCookies a,
.AcceptCookies a:visited {
  color: var(--red-text);
  text-decoration: none !important;
  font-weight: 500;
}