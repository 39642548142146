.EmojiReactionsOverlay {
  position: absolute;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2147483647;
}

.Emoji {
  opacity: 0;
  position: absolute;
  top: 90%;
  font-size: 32px;
}
