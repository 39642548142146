.EmojiReactions {
  position: relative;
  display: flex;
  padding: 0 8px;
}
.EmojiIcon {
  cursor: pointer;
  user-select: none;
  font-size: 20px;
}
.Disabled {
  mix-blend-mode: luminosity;
  opacity: 0.3;
}

.Disabled .EmojiIcon {
  cursor: default;
}