.Search {
  background: var(--grey-header);
  overflow: hidden;
}

.SearchInput {
  margin: 0 8px;
}

.SearchInput svg {
  fill: var(--text-grey-2);
}

@media screen and (orientation: landscape) {
  .Search { 
    width: -webkit-fill-available;
  }
}

@media screen and (orientation: portrait) and (min-width: 760px) {
  .Search { 
    width: -webkit-fill-available;
  }
}