.PublicRoomsScreen {
  position: relative;
  z-index: 1;
  width: 100%;
  scroll-snap-type: y proximity;
  scroll-snap-align: start;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: var(--grey-input-dark);
  padding: 0 14px 0 14px;
}

.Header {
  display: flex;
  flex-flow: row wrap;
}

.Right {
  width: 100%;
  margin-bottom: 42px;
}

.Right svg {
  transform: rotateY(180deg);
}

.PublicRoomsScreen h1 {
  padding-top: 60px;
  margin-top: 0;
}

.PublicRooms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20vh;
}

.CreatePublicRoom {
  width: 90%;
  margin-top: 24px;
  height: 180px;
  border-radius: var(--thumbnail-border-radius);
  background-color: var(--grey-dark-6);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: var(--text-grey-1);
  cursor: pointer;
}

.CreatePublicRoom:hover {
  background-color: var(--grey-dark-5);
}

.AddIcon {
  width: 48px !important;
  height: 48px !important;
  margin-bottom: 8px !important;
}

.PublicRoom {
  width: 90%;
  margin-top: 24px;
  cursor: pointer;
}

.PublicRoom:hover .RoomThumbnail {
  opacity: 0.7;
  transition: 0.16s ease-in;
}

.RoomThumbnailWrapper {
  position: relative;
  overflow: hidden;
  width: auto;
  height: 180px;
  border-radius: var(--thumbnail-border-radius);
  margin-bottom: 4px;
}

.RoomThumbnail {
  width: 100%;
  height: 180px;
  object-fit: cover;
  cursor: pointer;
  border-radius: var(--thumbnail-border-radius);
  transition: 0.26s ease-out;
}

.JoinIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px !important;
  height: 48px !important;
  transition: 0.26s ease-out;
  opacity: 0;
}

.PublicRoom:hover .JoinIcon {
  opacity: 1;
}

.RoomInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.RoomName {
  font-size: 20px;
  font-weight: 500;
}

.CurrentUserAmountWrapper {
  display: flex;
  align-items: center;
}

.Amount {
  margin-left: 8px;
}

.TagList>div {
  margin: 8px 4px;
}

.PublicRoomsScreen h2 {
  margin-bottom: 68px !important;
}

.NoPublicRooms {
  position: relative;
  padding-bottom: 20vh;
}

.PublicRoomsScreen h3 {
  margin-bottom: 0;
}

.NoPublicRooms h3 {
  width: 66%;
  margin-bottom: 0;
}

.NoPublicRooms p {
  width: 66%;
  font-size: 18px;
}

.Button {
  height: var(--button-height);
  font-size: 16px !important;
}

@media screen and (min-width: 750px) {
  .PublicRoomsScreen {
    padding: 0 64px;
  }

  .Right {
    width: 63%;
    margin: 0 auto;
    margin-bottom: 43px;
  }

  .PublicRooms {
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .PublicRoom,
  .CreatePublicRoom {
    width: 48%;
  }

  .PublicRooms>div:nth-child(odd) {
    margin-right: 4%;
  }
}

@media screen and (min-width: 1000px) {
  .Header  {
    padding-top: 90px;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 64px;
    flex-direction: row-reverse;
  }
  .Left {
    width: 45%;
  }
  
  .Right {
    width: 40%;
    margin: 60px 3% 0 0;
  }
}

@media screen and (min-width: 1100px) {
  .PublicRoom,
  .CreatePublicRoom {
    width: 32%;
  }

  .PublicRooms>div:nth-child(odd) {
    margin-right: 0;
  }

  .PublicRooms>div:not(:nth-child(3n)) {
    margin-right: 2%;
  }

}

@media screen and (min-width: 1200px) {
  .PublicRoomsScreen > * {
    margin-left: var(--side-margin) !important;
  }
}

@media screen and (min-width: 1500px) {
  .PublicRoom,
  .CreatePublicRoom {
    width: 24%;
  }
  .PublicRooms>div:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .PublicRooms>div:not(:nth-child(4n)) {
    margin-right: 1.3332%;
  }
  .Right {
    margin-right: 8%;
  }
}
