.Footer {
    background: var(--grey-header);
    padding: 0 14px 0 14px;
}

.Footer h1 {
    padding-top: 54px;
    font-size: 38px;
    margin-top: 0;
    color: var(--red-text);
    font-weight: 900;
}

.SocialMedia {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.SocialMedia > * {
    margin-right: 16px;
    transition: 0.13s ease-in;
}

.SocialMedia > *:hover *{
    fill: var(--red-text);
}

.Links {
    margin-top: 74px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 18px;
}

.Links a {
    margin-bottom: 8px;
    color: var(--text-grey-1);
    text-decoration: none;
    transition: 0.13s ease-in;
}

.Links a:hover {
    color: var(--red-text);
}

.CopyRight {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    padding-bottom: 32px;
    color: var(--text-grey-2);
}

.CopyRight svg {
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

@media screen and (min-width: 750px) {
    .Footer {
      padding: 0 64px;
    }

  }

@media screen and (min-width: 1200px) {
    .Footer > * {
        margin-left: var(--side-margin) !important;
    }
}