.UserList {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-top: 16px;
}

.User {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 48px;
  background: #181A20;
  border-radius: var(--input-border-radius);
  padding: 0 12px;
  margin: 4px 0;
}

.Left {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.CurrentUserIcon {
  margin-right: 6px !important;
}

.Right {
  display: flex;
  align-items: center;
}

.ActionIcon {
  cursor: pointer;
}

@media screen and (orientation: portrait) and (min-width: 768px) {
  .UserList {
    width: 50%;
  }
}

@media screen and (orientation: landscape) and (min-width: 800px) and (min-height: 650px) {
  .UserList {
    height: 65px;
    width: 100%;
    margin-top: 0;
    align-items: flex-start;
    flex-flow: row wrap;
  }
  .User {
    width: 18%;
    margin: 8px 4px 4px 8px;
  }
}

@media screen and (orientation: landscape) and (min-width: 800px) and (min-height: 1100px) {

}


@media screen and (orientation: landscape) and (min-width: 1800px) and (min-height: 1100px) {

}