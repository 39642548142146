.Room {
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
}

.RoomTabsContainerWrapper {
  height: 100%;
  overflow: hidden;
}

@media screen and (orientation: landscape) {
  .Room {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }
  .PlayerColumn {
    width: 55%;
  }
  .RoomTabsContainerWrapper {
    width: 45%;
  }
}

@media screen and (orientation: portrait) and (min-width: 760px) and (max-height: 1000px) {
  .RoomTabsContainerWrapper {
    width: 100%;
  }
}


@media screen and (orientation: portrait) and (min-width: 760px) {
  .RoomTabsContainerWrapper {
    width: 100%;
    display: flex;
  }

  .RoomTabsContainerWrapper>div {
    width: 50%;
  }
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  .PlayerColumn {
    flex-shrink: 100;
    width: 100%;
  }
  .RoomTabsContainerWrapper {
    width: 35%;
  }
}

@media screen and (orientation: landscape) and (min-width: 1000px) {
  .RoomTabsContainerWrapper {
    width: 32%;
    max-width: 380px;
  }
}
